import React from 'react';

class Comments extends React.Component {
    render() {
        return (
            <div className="padded-page">
                <center>
                    <h1>Comment and chat here</h1>
                    <h2>Page Is Under Construction</h2>
                    <img src="../../images/web_first_images_release.png" alt="Picks oage img" height="200px"
                        width="200px" />
                    <p style={{ fontStyle: "italic" }}>Version 1.0 Pick'm AppMode</p>
                </center>
            </div>
        )
    }
}

export default Comments;